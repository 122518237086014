import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loading$: Subject<boolean> = new ReplaySubject(1);

  constructor() {
    this.loading$.next(true);
  }

  public stopLoading() {
    this.loading$.next(false);
  }

  public startLoading() {
    this.loading$.next(true);
  }
}
