import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, OnDestroy {
  private canActivateProtectedRoutes: boolean;
  private authGuardSubscription: Subscription;

  constructor(private readonly authService: AuthService) {
    this.authGuardSubscription = this.authService.canActivateProtectedRoutes$.subscribe((canActivate) => {
      this.canActivateProtectedRoutes = canActivate;
    });
  }

  ngOnDestroy() {
    this.authGuardSubscription.unsubscribe();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.canActivateProtectedRoutes) {
      this.authService.login(next.routeConfig.path);
      return false;
    }
    return true;
  }
}
