import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loadingService/loading.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit, AfterViewInit {
  constructor(private readonly loadingService: LoadingService, public translate: TranslateService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.loadingService.stopLoading();
  }
}
