<div mat-dialog-title class="dialog-title">
  <h2 mat-dialog-title>Licenses of dependencies</h2>
  <button mat-dialog-close mat-icon-button aria-label="close dialog">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div *ngFor="let licenseType of licenseMap.keys()">
    <h2>{{ licenseType }}</h2>
    <ul>
      <ng-container *ngFor="let license of licenseMap.get(licenseType)">
        <li *ngIf="licenseType === license.licenses">
          <a *ngIf="license.url" [href]="license.url">{{ license.name }}@{{ license.version }}</a>
          <span *ngIf="!license.url">{{ license.name }}@{{ license.version }}</span>
          <ng-container *ngIf="license.url !== license.licenseUrl"
            >-
            <a [href]="license.licenseUrl">License</a>
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </div>
</mat-dialog-content>
