import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from '../../../services/loadingService/loading.service';
import { AuthService } from '../../../services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { SidenavService } from '../../../services/sidenavService/sidenav.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  private headerSubscriptions: Subscription[] = [];

  constructor(
    private readonly loadingService: LoadingService,
    private readonly authService: AuthService,
    private readonly router: Router,
    public sidenavService: SidenavService,
    public translate: TranslateService
  ) {
    this.headerSubscriptions.push(
      this.authService.isAuthenticated$.subscribe((next) => {
        this.isAuthenticated = next;
      })
    );
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.headerSubscriptions.forEach((s) => s.unsubscribe());
  }

  login() {
    this.loadingService.startLoading();
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  toggleSidenav() {
    this.sidenavService.toggle();
  }

  navigateToWelcome() {
    this.sidenavService.close();
    this.router.navigateByUrl('/');
  }
}
