<div mat-dialog-title class="dialog-title">
  <h2>Changelog</h2>
  <button mat-dialog-close mat-icon-button aria-label="close dialog">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div *ngFor="let version of changelog">
    <h2>Version {{ version.displayVersion }}:</h2>
    <ul>
      <ng-container *ngFor="let change of version.changes">
        <li>
          {{ 'versions.' + version.version + '.changes.' + change | translate }}
        </li>
      </ng-container>
    </ul>
  </div>
</mat-dialog-content>
