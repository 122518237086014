import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@Injectable()
export class LogMissingTranslationsInBackend extends MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    // Triggers frontend-logger
    throw Error('Missing translation: ' + params.key);
  }
}

export function createTranslateLoader(http: HttpClient) {
  // Loader for the translation files
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
