import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loadingService/loading.service';
import json from '../../../assets/i18n/de.json';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
})
export class ChangelogComponent implements OnInit {
  changelog: any;

  constructor(private readonly loadingService: LoadingService) {
    this.changelog = this.versionsToChangelogArray(json.versions);
    loadingService.stopLoading();
  }

  ngOnInit(): void {}

  versionsToChangelogArray(versions) {
    const changelog = [];
    // eslint-disable-next-line guard-for-in
    for (const key in versions) {
      const changes = [];
      // eslint-disable-next-line guard-for-in
      for (const changeKey in versions[key].changes) {
        changes.push(changeKey);
      }
      changelog.push({
        version: key,
        displayVersion: key.replace(/-/g, '.'),
        changes,
      });
    }
    return changelog;
  }
}
