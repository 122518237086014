import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SidenavService } from '../../services/sidenavService/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { ThemeService } from '../../services/themeService/theme.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sidenav') public sidenav: MatSidenav;
  mainLayoutSubscription: Subscription;
  isFullWidth = false;

  constructor(private readonly sidenavService: SidenavService, private readonly themeService: ThemeService) {
    this.mainLayoutSubscription = themeService.isFullWidth$.subscribe((isFullWidth) => {
      this.isFullWidth = isFullWidth;
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  ngOnDestroy(): void {
    this.mainLayoutSubscription.unsubscribe();
  }
}
