<div id="splash-screen" class="splash-screen">
  <div class="splash-screen-logo">
    <!-- Put svg logo here and put the same logo in index.html -->

    <div class="spinner-wrapper">
      <svg class="splash-screen-spinner" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle class="spinner-circle" cx="50" cy="50" r="45" />
      </svg>
    </div>
  </div>
</div>
