import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading/loading.component';
import { SharedModule } from '../shared/shared.module';
import { AuthGuard } from '../services/_guards/auth.guard.service';
import { WelcomeComponent } from './start-page/welcome.component';
import { HttpClientModule } from '@angular/common/http';
import { ComponentsModule } from '../components/components.module';
import { MainLayoutComponent } from '../layouts/main-layout/main-layout.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AdminGuard } from '../services/_guards/admin-guard.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { UserGuard } from '../services/_guards/user.guard.service';
import { SalesGuard } from '../services/_guards/sales-guard.service';

const routes: Routes = [
  {
    path: 'admin',
    component: MainLayoutComponent,
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'sales',
    component: MainLayoutComponent,
    loadChildren: () => import('./sales/sales.module').then((m) => m.SalesModule),
    canActivate: [SalesGuard],
  },
  {
    path: 'user',
    component: MainLayoutComponent,
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    canActivate: [UserGuard],
  },
  {
    path: 'auth',
    component: MainLayoutComponent,
    loadChildren: () => import('./authorized/authorized.module').then((m) => m.AuthorizedModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'unauthorized',
    component: MainLayoutComponent,
    children: [{ path: '', component: UnauthorizedComponent }],
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [{ path: '', component: WelcomeComponent }],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    SharedModule,
    ComponentsModule,
    MatButtonModule,
    MatSnackBarModule,
  ],
  exports: [RouterModule, LoadingComponent],
  declarations: [LoadingComponent, WelcomeComponent, UnauthorizedComponent],
})
export class PagesModule {}
