import { Injectable, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidenavService implements OnDestroy {
  private sidenav: MatSidenav;
  private sidenavSubject = new BehaviorSubject<boolean>(false);
  public opened;
  private sidenavSubscriptions = [];

  ngOnDestroy() {
    /* to ensure that the subscription is not undefined */
    if (this.sidenavSubscriptions && this.sidenavSubscriptions.length !== 0) {
      this.sidenavSubscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
    this.sidenavSubscriptions.push(
      this.sidenav._openedStream.subscribe(() => {
        this.opened = true;
      })
    );
    this.sidenavSubscriptions.push(
      this.sidenav._closedStream.subscribe(() => {
        this.opened = false;
      })
    );
  }

  public open() {
    return this.sidenav.open();
  }

  public close() {
    this.opened = false;
    return this.sidenav.close();
  }

  public toggle(): void {
    this.sidenav.toggle();
  }
}
