<div class="h-100 w-100">
  <mat-sidenav-container class="h-100 w-100">
    <mat-sidenav #sidenav mode="side" class="sidenav">
      <app-main-sidenav></app-main-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
      <app-main-header></app-main-header>

      <main role="main" class="main-content" [class.default-width]="!isFullWidth" [class.full-width]="isFullWidth">
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
