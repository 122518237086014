import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from './services/loadingService/loading.service';
import { AuthService } from './services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import defaultLanguage from './../assets/i18n/de.json';
import { ThemeService } from './services/themeService/theme.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Category, CategoryService } from './services/_http/category.service';
import { EmployeeService } from './services/_http/employee.service';
import { combineLatest } from 'rxjs';
import { TimesheetTableViewService } from './services/timesheetTableViewService/timesheet-table-view.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class') componentCssClass;

  private isAdminPreload = false;
  private isPreload = false;
  private appSubscriptions = [];

  constructor(
    private readonly loadingService: LoadingService,
    private readonly authService: AuthService,
    private readonly themeService: ThemeService,
    private readonly translate: TranslateService,
    private readonly categoryService: CategoryService,
    private readonly employeeService: EmployeeService,
    private readonly timesheetTableViewService: TimesheetTableViewService,
    public overlayContainer: OverlayContainer
  ) {
    this.loadingService.startLoading();
    this.authService.runInitialLoginSequence().then();
    translate.addLangs(['en', 'de']);
    translate.setTranslation('de', defaultLanguage);
    translate.setDefaultLang('de'); // Fallback-Language

    this.appSubscriptions.push(
      themeService.getSelectedThemeObservable().subscribe((newTheme) => {
        this.componentCssClass = newTheme;
      })
    );

    this.appSubscriptions.push(
      combineLatest([this.authService.isAuthenticated$, this.authService.hasAdminRole$]).subscribe(
        ([isAuth, isAdmin]) => {
          if (isAuth) {
            this.employeeService.getEmployee();
          }
          if (isAuth && isAdmin) {
            this.employeeService.getAllEmployees();
          }
          if (isAuth && isAdmin && !this.isAdminPreload) {
            this.categoryService.updateCategoryData(Category.ALL);
            this.isAdminPreload = true;
          } else if (isAuth && !this.isPreload) {
            this.categoryService.updateCategoryData(Category.ALL);
            this.isPreload = true;
          }
        }
      )
    );
  }

  ngOnInit() {
    const theme = window.localStorage.getItem('selectedTheme');
    if (theme) {
      this.themeService.setSelectedTheme(theme);
    }
    const width = window.localStorage.getItem('selectedWidth');
    if (width) {
      this.themeService.setSelectedWidth(width);
    }
    const timesheetTable = window.localStorage.getItem('selectedTimesheetTable');
    if (timesheetTable) {
      this.timesheetTableViewService.setSelectedTimeSheetTableView(timesheetTable);
    }

    const language = window.localStorage.getItem('lang');
    if (language && this.translate.getLangs().includes(language)) {
      this.translate.use(language);
    }
  }

  ngOnDestroy() {
    this.appSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
