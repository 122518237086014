import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PanelClass, TranslatedSnackBarService } from '../translatedSnackBarService/translated-snack-bar.service';
import { tap, throwError } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { getBasePath } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractHttpService {
  protected path: string;

  protected constructor(
    protected readonly http: HttpClient,
    protected readonly authService: AuthService,
    protected readonly translatedSnackBar: TranslatedSnackBarService,
    path: string
  ) {
    this.path = getBasePath() + path;
  }

  protected handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('An error occurred:', error.error);
    } else if (error.status === 401) {
      this.translatedSnackBar.openDefault('error.not_authenticated', PanelClass.WARN);
      this.authService.logout();
      return;
    } else if (error.status === 400) {
      this.translatedSnackBar.openDefault('error.backend_error', PanelClass.WARN);
      return;
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  protected getAndSortById(url: string) {
    return this.http
      .get(this.path + url)
      .pipe(catchError(this.handleError.bind(this)))
      .pipe(
        tap((results) => {
          results.sort((a, b) => a.id - b.id);
        })
      );
  }
}
