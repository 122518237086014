// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: window['env']['production'],
  backend: window['env']['apiUrl'],
  webSocketUrl: window['env']['webSocket'],
  oidcAuthority: window['env']['oidcAuthority'],
  oidcClientId: window['env']['oidcClientId'],
  dummyClientSecret: window['env']['dummyClientSecret'],
};

export function getBasePath(): string {
  let currentDomainHttps = '';
  if (environment.production === true) {
    switch (window.location.hostname) {
      case 'tempora.ppi.de':
        currentDomainHttps = `https://tempora.ppi.de/api`;
        break;
      case 'perditempo.dmz.ppi.de':
        currentDomainHttps = `https://perditempo.dmz.ppi.de/api`;
        break;
      default:
        break;
    }
  } else {
    currentDomainHttps = `${environment.backend}/api`;
  }
  return currentDomainHttps;
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
