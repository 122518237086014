import { BusinessMessage } from './business-message';

export class ResponseDto<E> {
  data?: E;
  businessMessages: BusinessMessage[];

  constructor(data: E, businessMessages: BusinessMessage[]) {
    this.data = data;
    this.businessMessages = businessMessages;
  }
}
