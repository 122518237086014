import { BehaviorSubject, Subject, Subscription, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PanelClass, TranslatedSnackBarService } from '../translatedSnackBarService/translated-snack-bar.service';
import { ResponseDto } from '../../model/response-dto';
import moment, { Moment } from 'moment/moment';
import { AbstractHttpService } from './abstract-http.service';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Company } from './company.service';
import { Unit } from './unit.service';
import { Team } from './team.service';

export interface Employee {
  id: number;
  firstName: string;
  lastName: string;
  monthLockedFrom: string;
  shortTag: string;
  type: string;
  weeklyWorkingHours: number;
  employeeNumber: string;
  location: string;
  gender: string;
  dateOfBirth: string;
  company: Company;
  unit: Unit;
  team: Team;
  managerId: number;
  threshold: number;
  thresholdStart: string;
  employmentEntrance: string;
  employmentResignation: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmployeeService extends AbstractHttpService implements OnDestroy {
  private employeeSubscriptions = [];
  private readonly employeeDataSubject$ = new BehaviorSubject<any>([]);

  public adminEmployeeData$ = this.employeeDataSubject$.asObservable();
  private readonly myEmployeeDataSubject$ = new BehaviorSubject<Employee>({
    id: null,
    firstName: null,
    lastName: null,
    monthLockedFrom: null,
    shortTag: null,
    type: null,
    weeklyWorkingHours: null,
    employeeNumber: null,
    location: null,
    gender: null,
    dateOfBirth: null,
    company: null,
    unit: null,
    team: null,
    managerId: null,
    threshold: null,
    thresholdStart: null,
    employmentEntrance: null,
    employmentResignation: null,
  });

  public userEmployeeData$ = this.myEmployeeDataSubject$.asObservable();

  public readonly rowClickedSubject$ = new Subject<{ data: any }>();

  public rowClicked$ = this.rowClickedSubject$.asObservable();

  private readonly userMonthLockedFromSubject$ = new Subject<ResponseDto<Moment>>();

  public userMonthLockedFrom$ = this.userMonthLockedFromSubject$.asObservable();

  constructor(
    protected readonly http: HttpClient,
    protected readonly authService: AuthService,
    protected readonly translatedSnackBar: TranslatedSnackBarService
  ) {
    super(http, authService, translatedSnackBar, `/v1/employee`);
  }

  ngOnDestroy() {
    /* to ensure that the subscription is not undefined */
    if (this.employeeSubscriptions && this.employeeSubscriptions.length !== 0) {
      this.employeeSubscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /** Make a backend API call which creates a new user. If the user ID
   *  matches an existing user, that user is replaced.
   *
   * @param employee an Employee object with all info.
   */
  public createOrUpdateEmployee(employee: Employee) {
    return this.http.post(this.path, employee).pipe(catchError(this.handleError.bind(this)));
  }

  /** Fetches all employees' data from the backend. */
  public getAllEmployees(): Subscription {
    const getAllEmployeeSubscription = this.http
      .get(this.path)
      .pipe(
        catchError(this.handleError.bind(this)),
        tap((results) => results.sort((a, b) => a.id - b.id))
      )
      .subscribe((data) => this.employeeDataSubject$.next(data));
    this.employeeSubscriptions.push(getAllEmployeeSubscription);
    return getAllEmployeeSubscription;
  }

  /** Fetches the current user's data from the backend. This function is the
   *  inverse of createOrUpdateEmployee(). */
  public getEmployee(): object {
    const getEmployeeSubscription = this.http
      .get(this.path + '/me')
      .pipe(catchError(this.handleError.bind(this)))
      .subscribe((data) => this.myEmployeeDataSubject$.next(data));
    this.employeeSubscriptions.push(getEmployeeSubscription);
    return getEmployeeSubscription;
  }

  /** Fetches the month from which the current user cannot alter their
   *  historic entries. */
  public getMonthLockedFrom(): Subscription {
    const getMonthLockedFromSubscription = this.http
      .get<ResponseDto<Moment>>(this.path + '/lock')
      .pipe(catchError(this.handleError.bind(this)))
      .subscribe((res: any) =>
        this.userMonthLockedFromSubject$.next(new ResponseDto<Moment>(moment.utc(res.data), res.businessMessages))
      );
    this.employeeSubscriptions.push(getMonthLockedFromSubscription);
    return getMonthLockedFromSubscription;
  }

  /** Delete employee from the backend.
   *
   * @param id the numeric identifier in the database.
   */
  public deleteEmployee(id: any): object {
    const getDeleteEmployeeSubscription = this.http
      .delete(this.path + '/' + id)
      .pipe(catchError(this.handleError.bind(this)))
      .subscribe(() => {
        this.translatedSnackBar.openDefault('delete-successful', PanelClass.ACCENT);
        this.getAllEmployees();
      });
    this.employeeSubscriptions.push(getDeleteEmployeeSubscription);
    return getDeleteEmployeeSubscription;
  }
}
