import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { frontendVersion } from '../../../environments/version';
import { getBasePath } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class VersionService implements OnDestroy {
  coreVersion$: Subject<string> = new ReplaySubject(1);
  frontendVersion: string = frontendVersion;
  private versionSubscription: Subscription;

  constructor(private readonly http: HttpClient) {
    this.versionSubscription = this.http.get<any>(`${getBasePath()}/v1/version`).subscribe((version) => {
      this.coreVersion$.next(version.version);
    });
  }

  ngOnDestroy() {
    this.versionSubscription.unsubscribe();
  }
}
