import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LicenseInformationComponent } from './license-information/license-information.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ChipsAutocompleteInputComponent } from './form-fields/chips-autocomplete/chips-autocomplete-input.component';
import { AutocompleteComponent } from './form-fields/autocomplete/autocomplete.component';
import { MatInputModule } from '@angular/material/input';
import { TableComponent } from './table/table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { HighlightedTextComponent } from './highlighted-text/highlighted-text.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PipesModule } from '../pipes/pipes.module';
import { ChangelogComponent } from './changelog/changelog.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    LicenseInformationComponent,
    ChipsAutocompleteInputComponent,
    AutocompleteComponent,
    TableComponent,
    HighlightedTextComponent,
    ChangelogComponent,
  ],
  imports: [
    SharedModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatPaginatorModule,
    MatCheckboxModule,
    PipesModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    LicenseInformationComponent,
    ChipsAutocompleteInputComponent,
    AutocompleteComponent,
    TableComponent,
    ChangelogComponent,
  ],
})
export class ComponentsModule {}
