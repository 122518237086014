import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loadingService/loading.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent implements OnInit, OnDestroy {
  private loadingSubscription: Subscription;

  constructor(
    private loadingService: LoadingService,
    private _elmRef: ElementRef,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this._elmRef.nativeElement.style.display = 'none';
    this.loadingSubscription = this.loadingService.loading$.subscribe((loadingState) => {
      this._elmRef.nativeElement.style.display = loadingState ? 'block' : 'none';
    });
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }
}
