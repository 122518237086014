import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Angulartics2Module } from 'angulartics2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './modules/auth/auth.module';
import { createTranslateLoader, LogMissingTranslationsInBackend } from './util/translation-utils';
import { MainLayoutModule } from './layouts/main-layout/main-layout.module';
import { ErrorService } from './services/errorService/error.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [AppComponent],
  imports: [
    MainLayoutModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    PagesModule,
    HttpClientModule,
    AuthModule.forRoot(),
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
      },
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    MatToolbarModule,
    OverlayModule,
    TranslateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorService,
    },
    {
      provide: MissingTranslationHandler,
      useClass: LogMissingTranslationsInBackend,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
