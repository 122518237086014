import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { LoadingService } from '../../services/loadingService/loading.service';
import { VersionService } from '../../services/versionService/version.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LicenseInformationComponent } from '../../components/license-information/license-information.component';
import { ThemeService } from '../../services/themeService/theme.service';
import { ChangelogComponent } from '../../components/changelog/changelog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements AfterViewInit, OnDestroy {
  isDark = false;
  private welcomeSubscription: Subscription;

  constructor(
    private readonly loadingService: LoadingService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly themeService: ThemeService,
    public readonly translate: TranslateService,
    public readonly versionService: VersionService,
    public readonly authService: AuthService
  ) {
    this.welcomeSubscription = this.themeService.isDark$.subscribe((isDark) => (this.isDark = isDark));
  }

  ngAfterViewInit(): void {
    this.loadingService.stopLoading();
  }

  ngOnDestroy() {
    this.welcomeSubscription.unsubscribe();
  }

  login() {
    this.loadingService.startLoading();
    this.authService.login('/user');
  }

  useLanguage(lang: string) {
    this.translate.use(lang);
    window.localStorage.setItem('lang', lang);
  }

  openLicenseAttributionDialog() {
    this.dialog.open(LicenseInformationComponent);
  }

  toTimesheet() {
    this.router.navigateByUrl('/user');
  }

  toAdmin() {
    this.router.navigateByUrl('/admin');
  }

  toSales() {
    this.router.navigateByUrl('/sales');
  }

  openChangelog() {
    this.dialog.open(ChangelogComponent);
  }
}
