<div class="h-100 w-100 overflow-hidden">
  <div class="row mx-auto h-100 justify-content-center align-items-center">
    <div class="fit-content me-4 mb-4">
      <div class="mt-4 h1">
        {{ 'welcome_header' | translate }}
      </div>
      <div class="h2">
        {{ 'welcome_text' | translate }}
      </div>
      <span class="text-muted" id="textFrontendVersionNumber">
        {{ 'tempora_version' | translate }}
        {{ versionService.frontendVersion }}
      </span>
      &nbsp;
      <div class="col-12 mt-3">
        <ng-container>
          <button
            mat-flat-button
            class="mt-2 mx-1"
            color="primary"
            id="buttonLogin"
            *ngIf="(this.authService.canActivateProtectedRoutes$ | async) === false"
            (click)="login()"
          >
            {{ 'login' | translate }}
          </button>
          <button
            mat-flat-button
            class="mt-2 mx-1"
            color="primary"
            id="buttonTimesheet"
            *ngIf="(this.authService.canActivateProtectedRoutes$ | async) && (this.authService.hasUserRole$ | async)"
            (click)="toTimesheet()"
          >
            {{ 'to-timesheet' | translate }}
          </button>
          <button
            mat-flat-button
            class="mt-2 mx-1"
            color="primary"
            id="buttonSales"
            *ngIf="(this.authService.canActivateProtectedRoutes$ | async) && (this.authService.hasSalesRole$ | async)"
            (click)="toSales()"
          >
            {{ 'to-sales' | translate }}
          </button>
          <button
            mat-flat-button
            class="mt-2 mx-1"
            color="primary"
            id="buttonAdmin"
            *ngIf="(this.authService.canActivateProtectedRoutes$ | async) && (this.authService.hasAdminRole$ | async)"
            (click)="toAdmin()"
          >
            {{ 'to-admin' | translate }}
          </button>
        </ng-container>
      </div>
      <div class="col-12 mt-3">
        <button mat-flat-button class="mt-2 mx-1" color="accent" (click)="openLicenseAttributionDialog()">
          {{ 'view_licenses' | translate }}
        </button>
        <button mat-flat-button class="mt-2 mx-1" color="accent" (click)="openChangelog()">
          {{ 'view_changelog' | translate }}
        </button>
      </div>
    </div>
    <div class="fit-content" [class.glass-d]="isDark" [class.glass-l]="!isDark">
      <img src="assets/icons/tempora.svg" class="justify-content-start logo px-1 py-3" alt="Tempora Logo" />
    </div>
  </div>
</div>
