import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, interval, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  private readonly isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private readonly isUserSubject$ = new BehaviorSubject<boolean>(false);
  public hasUserRole$ = this.isUserSubject$.asObservable();

  private readonly isAdminSubject$ = new BehaviorSubject<boolean>(false);
  public hasAdminRole$ = this.isAdminSubject$.asObservable();

  private readonly isSalesSubject$ = new BehaviorSubject<boolean>(false);
  public hasSalesRole$ = this.isSalesSubject$.asObservable();

  private readonly isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map((values) => values.every((b) => b)));
  private authSubscriptions = [];

  private navigateToLoginPage() {
    console.warn('Navigating to /should-login');
    this.router.navigateByUrl('/should-login').then();
  }

  constructor(private readonly oauthService: OAuthService, private readonly router: Router) {
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.authSubscriptions.push(
      this.oauthService.events.subscribe((_) => {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      })
    );

    this.authSubscriptions.push(
      this.oauthService.events
        .pipe(filter((e) => ['discovery_document_loaded', 'token_received'].includes(e.type)))
        .subscribe((e) => {
          if (this.oauthService.hasValidAccessToken()) {
            this.oauthService.loadUserProfile().then((r) => {
              // @ts-ignore
              this.refreshRoles(r.info.resource_access.realm_roles);
            });
          }
        })
    );

    this.authSubscriptions.push(
      this.oauthService.events
        .pipe(filter((e) => ['session_terminated', 'session_error'].includes(e.type)))
        .subscribe((e) => this.navigateToLoginPage())
    );

    this.authSubscriptions.push(interval(60000).subscribe(() => this.checkTokenExpiryAndRefresh()));
  }

  ngOnDestroy() {
    this.authSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public runInitialLoginSequence(): Promise<void> {
    return this.oauthService
      .loadDiscoveryDocument()
      .then(() => this.oauthService.tryLogin())
      .then(() => Promise.resolve())
      .then(() => {
        this.isDoneLoadingSubject$.next(true);
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          let stateUrl = this.oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          this.router.navigateByUrl(stateUrl).then();
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public logout() {
    this.oauthService.logOut();
  }

  public refresh() {
    this.oauthService.silentRefresh();
  }

  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }

  private refreshRoles(roles: string[]): void {
    this.isAdminSubject$.next(roles ? roles.includes('TEMPORA_ADMIN') : false);
    this.isUserSubject$.next(roles ? roles.includes('TEMPORA_USER') : false);
    this.isSalesSubject$.next(roles ? roles.includes('TEMPORA_SALES') : false);
  }

  private checkTokenExpiryAndRefresh() {
    const tokenExpiry = this.oauthService.getAccessTokenExpiration();
    const now = new Date().getTime();
    const expiresIn = tokenExpiry - now;

    if (expiresIn < 60000) {
      // Refresh if less than 60 seconds remaining
      this.oauthService
        .refreshToken()
        .then(() => {})
        .catch((err) => {
          console.error('Error refreshing token', err);
          this.navigateToLoginPage();
        });
    }
  }
}
