import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getBasePath } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LicensesService {
  constructor(private readonly http: HttpClient) {}

  getLicenses() {
    return this.http.get(`${getBasePath()}/v1/licenses`);
  }
}
