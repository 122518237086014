import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.oidcAuthority,
  redirectUri: `${window.location.origin}/?callback=true`,
  clientId: environment.oidcClientId,
  dummyClientSecret: environment.dummyClientSecret ?? 'eb7173e2-e5a8-4700-96ad-814900713009',
  scope: 'openid profile email offline_access',
  responseType: 'code',
  timeoutFactor: 0.75,
  useSilentRefresh: false, // Needed for Code Flow to suggest using iframe-based refreshes
  sessionChecksEnabled: false,
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
};
