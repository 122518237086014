import { BehaviorSubject, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AbstractHttpService } from './abstract-http.service';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { PanelClass, TranslatedSnackBarService } from '../translatedSnackBarService/translated-snack-bar.service';
import moment from 'moment';
import { cloneDeep } from 'lodash-es';
import { Combination } from './timesheet.service';

export enum Category {
  ALL,
  CAT1,
  CAT2,
  CAT3,
  ROLE,
}

enum AccountRolePath {
  USER = 'user',
  ADMIN = 'admin',
}

export enum CategoryPath {
  CAT1 = 'cat-1',
  CAT2 = 'cat-2',
  CAT3 = 'cat-3',
  ROLE = 'role',
}

export interface CategoryLeaf {
  data: any;
  children: Object;
  roles: any;
  type: Category;
}

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends AbstractHttpService implements OnDestroy {
  private readonly category1DataSubject$ = new BehaviorSubject<any>([]);
  public category1Data$ = this.category1DataSubject$.asObservable();

  private readonly category2DataSubject$ = new BehaviorSubject<any>([]);
  public category2Data$ = this.category2DataSubject$.asObservable();

  private readonly category3DataSubject$ = new BehaviorSubject<any>([]);
  public category3Data$ = this.category3DataSubject$.asObservable();

  private readonly adminRoleSubject$ = new BehaviorSubject<any>([]);
  public adminRoleData$ = this.adminRoleSubject$.asObservable();

  private readonly userRoleSubject$ = new BehaviorSubject<any>([]);
  public userRoleData$ = this.userRoleSubject$.asObservable();

  private readonly roleEmployeeDataSubject$ = new BehaviorSubject<any>([]);
  public roleEmployeeData$ = this.roleEmployeeDataSubject$.asObservable();

  private readonly employeeCategoriesTreeSubject$ = new BehaviorSubject<any>([]);
  public employeeCategoriesTree$ = this.employeeCategoriesTreeSubject$.asObservable();

  private readonly employeeCategoriesCombinationsSubject$ = new BehaviorSubject<any>([]);
  public employeeCategoriesCombinations$ = this.employeeCategoriesCombinationsSubject$.asObservable();

  public readonly rowClickedSubject$ = new BehaviorSubject<{ category: Category; data: any }>(null);
  public rowClicked$ = this.rowClickedSubject$.asObservable();
  private isAdmin = false;
  private categoriesSubscriptions = [];

  constructor(
    protected readonly http: HttpClient,
    protected readonly authService: AuthService,
    protected readonly translatedSnackBar: TranslatedSnackBarService
  ) {
    super(http, authService, translatedSnackBar, `/v1/cat`);
    this.categoriesSubscriptions.push(
      this.authService.hasAdminRole$.subscribe((isAdmin) => {
        this.isAdmin = isAdmin;
      })
    );
  }

  ngOnDestroy() {
    this.categoriesSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public updateCategoryData(...categories: Category[]) {
    const refCategories = categories ? categories : [Category.ALL];
    if (refCategories.includes(Category.CAT1)) {
      this.categoriesSubscriptions.push(
        this.getAndSortById('/cat-1').subscribe((data) => this.category1DataSubject$.next(data))
      );
    }
    if (refCategories.includes(Category.CAT2)) {
      this.categoriesSubscriptions.push(
        this.getAndSortById('/cat-2').subscribe((data) => this.category2DataSubject$.next(data))
      );
    }
    if (refCategories.includes(Category.CAT3)) {
      this.categoriesSubscriptions.push(
        this.getAndSortById('/cat-3').subscribe((data) => this.category3DataSubject$.next(data))
      );
    }
    if (refCategories.includes(Category.ROLE)) {
      this.categoriesSubscriptions.push(
        this.getAndSortById('/role/user').subscribe((data) => {
          this.userRoleSubject$.next(data);
        })
      );
      this.categoriesSubscriptions.push(
        this.getAndSortById('/role/me').subscribe((data) => this.roleEmployeeDataSubject$.next(data))
      );
      if (this.isAdmin) {
        this.categoriesSubscriptions.push(
          this.getAndSortById('/role/admin').subscribe((data) => this.adminRoleSubject$.next(data))
        );
      }
    }
    if (refCategories.includes(Category.ALL)) {
      this.categoriesSubscriptions.push(
        this.getAllAndSort(AccountRolePath.USER).subscribe((data) => {
          this.category1DataSubject$.next(data['category1List']);
          this.category2DataSubject$.next(data['category2List']);
          this.category3DataSubject$.next(data['category3List']);
          this.userRoleSubject$.next(data['roleList']);
        })
      );
      this.categoriesSubscriptions.push(
        this.getAndSortById('/role/me').subscribe((data) => this.roleEmployeeDataSubject$.next(data))
      );
      if (this.isAdmin) {
        this.categoriesSubscriptions.push(
          this.getAllAndSort(AccountRolePath.ADMIN).subscribe((data) => {
            this.adminRoleSubject$.next(data['roleList']);
          })
        );
      }
    }
  }

  public createOrUpdateCategory(category: CategoryPath, categoryData: any) {
    const categoryPath = category === CategoryPath.ROLE ? `${category}/admin` : category;
    return this.http.post(`${this.path}/${categoryPath}`, categoryData).pipe(catchError(this.handleError.bind(this)));
  }

  public deleteAndUpdate(categoryPath: CategoryPath, id: any) {
    const deleteAndUpdateSubscription = this.http
      .delete(`${this.path}/${categoryPath}/${id}`)
      .pipe(
        catchError(this.handleError.bind(this)),
        tap((response: any) => this.openDeletionResponseSnackbar(response))
      )
      .subscribe((res) => {
        if (res.data) {
          this.updateCategoryData(Category.ALL);
        }
      });
    this.categoriesSubscriptions.push(deleteAndUpdateSubscription);
    return deleteAndUpdateSubscription;
  }

  private getAllAndSort(role: AccountRolePath) {
    return this.http
      .get(this.path + '/all/' + role)
      .pipe(catchError(this.handleError.bind(this)))
      .pipe(
        tap((results) => {
          results.category1List.sort((a, b) => a.id - b.id);
          results.category2List.sort((a, b) => a.id - b.id);
          results.category3List.sort((a, b) => a.id - b.id);
          results.roleList.sort((a, b) => a.id - b.id);
        })
      );
  }

  openDeletionResponseSnackbar(response: any) {
    if (!response.data && response.businessMessages.length !== 0) {
      this.translatedSnackBar.openDefault(
        'admin.validation.delete.' + response.businessMessages[0].id,
        PanelClass.WARN
      );
    } else {
      this.translatedSnackBar.openDefault('delete-successful', PanelClass.ACCENT);
    }
  }

  generateTree(cat1Data: any, cat2Data: any, cat3Data: any, roleData: any, month: any, generateCombinations: boolean) {
    const categoryTree = {};
    /* Clone the stored values from the subscriptions */
    let categories1 = cloneDeep(cat1Data);
    let categories2 = cloneDeep(cat2Data);
    let categories3 = cloneDeep(cat3Data);
    let employeeRoles = cloneDeep(roleData);
    /* Prep the roles to be added to the tree */
    // We need Cat_1, Cat_2, and Roles to build the tree; Cat_3 is optional.
    if (!categories1.length || !categories2.length || !employeeRoles.length) {
      return;
    }
    //console.log('generateTree....');
    /* Filter the Employee Roles to remove the ones that aren't active. */
    categories1 = this.filterCategoryListForAvailability(categories1, month);
    categories2 = this.filterCategoryListForAvailability(categories2, month);
    categories3 = this.filterCategoryListForAvailability(categories3, month);
    employeeRoles = this.filterCategoryListForAvailability(employeeRoles, month);
    /* Build the category tree, starting with Category 1 */
    const eRoles = new Set();
    employeeRoles.forEach((role) => role.category1List.forEach((r) => eRoles.add(r)));
    for (const cat of categories1) {
      categoryTree[cat.id] = {
        data: cat,
        children: {},
        roles: {},
        type: Category.CAT1,
      } as CategoryLeaf;
    }
    employeeRoles.forEach((eRole) => {
      eRole.category1List.forEach((c1) => {
        if (Object.keys(categoryTree).includes(String(c1))) {
          categoryTree[c1].roles[eRole.id] = eRole;
        }
      }, this);
    }, this);

    /* Add the Category 2 as children of Category 1 */
    const cat2 = [];
    const cat1 = [];
    for (const cat of categories2) {
      if (categoryTree.hasOwnProperty(cat.category1)) {
        categoryTree[cat.category1].children[cat.id] = {
          data: cat,
          children: {},
          roles: {},
          type: Category.CAT2,
        } as CategoryLeaf;
        cat2.push(cat.id);
        cat1.push(cat.category1);
      }
    }
    /* Add the roles for Category 2 */
    employeeRoles.forEach((eRole) =>
      eRole.category2List.forEach((c2) => {
        const c2Index = cat2.indexOf(c2);
        if (c2Index > -1) {
          categoryTree[cat1[c2Index]].children[c2].roles[eRole.id] = eRole;
        }
      })
    );

    /* Add the Category 3 as children of Category 2 */
    if (categories3.length) {
      for (const cat of categories3) {
        /* Get all roles assigned to the category 3 */
        const cat3Roles = {};
        employeeRoles.forEach((eRole) => {
          if (eRole.category3List.includes(cat.id)) {
            cat3Roles[eRole.id] = eRole;
          }
        });
        /* Find the Cat_1 -> Cat_2 -> Cat_3 hierarchy */
        const overlap = cat.category2Set.filter((value) => cat2.includes(value));
        const c2Index = overlap.map((value) => cat2.indexOf(value));
        /* Append the Category 3 to all Category 2s */
        if (c2Index.length) {
          for (let i = 0; i < c2Index.length; i++) {
            /* Append the Cat_3 to the correct Cat_1 -> Cat_2 */
            categoryTree[cat1[c2Index[i]]].children[[overlap[i]]].children[cat.id] = {
              data: cat,
              children: null,
              roles: cat3Roles,
              type: Category.CAT3,
            } as CategoryLeaf;
          }
        }
      }
    }
    //console.log('categoryTree before deleting', categoryTree);
    // Remove the Categories which have no roles and therefore cannot be
    // selected. Depth-first-search, propagate down and stop when we have an
    // assigned role. Should no role be found after all children have been
    // iterated through,delete the Category since it cannot be selected.

    Object.keys(categoryTree).forEach((keyC1) => {
      const c1 = categoryTree[keyC1];
      if (Object.keys(c1.roles).length) {
        return true;
      }
      let c2RoleExists = false;
      Object.keys(categoryTree[keyC1].children).forEach((keyC2) => {
        if (Object.keys(categoryTree[keyC1].children[keyC2].roles).length) {
          c2RoleExists = true;
          return true;
        }
        let c3RoleExists = false;
        Object.keys(categoryTree[keyC1].children[keyC2].children).forEach((keyC3) => {
          if (Object.keys(categoryTree[keyC1].children[keyC2].children[keyC3].roles).length) {
            c2RoleExists = true;
            c3RoleExists = true;
            return true;
          } else {
            delete categoryTree[keyC1].children[keyC2].children[keyC3];
          }
        });
        if (!c3RoleExists) {
          delete categoryTree[keyC1].children[keyC2];
        }
      });
      if (!c2RoleExists) {
        delete categoryTree[keyC1];
      }
    });
    //console.log('generatedTree', categoryTree);
    this.employeeCategoriesTreeSubject$.next(categoryTree);
    const combinations = this.convertTreeToCombinations(categoryTree);
    if (generateCombinations) {
      this.employeeCategoriesCombinationsSubject$.next(combinations);
    } else {
      return combinations;
    }
  }

  /**
   * Converts a categories tree structure to an array of all possible combinations.
   *
   * @param root - The root node of the tree structure.
   * @returns An array of combinations.
   */
  convertTreeToCombinations(root: any): Combination[] {
    const combinations: Combination[] = [];
    const roots = Object.values(root) as CategoryLeaf[];
    roots.forEach((value) => {
      this.traverseTree(
        value,
        new Set<[number, string, string, string]>(),
        {
          cat1: '',
          cat2: '',
          cat3: '',
          role: '',
          validFrom: '',
          validTo: '',
          mon: { id: null, time: '0:00', isAvailable: false },
          tue: { id: null, time: '0:00', isAvailable: false },
          wed: { id: null, time: '0:00', isAvailable: false },
          thu: { id: null, time: '0:00', isAvailable: false },
          fri: { id: null, time: '0:00', isAvailable: false },
          sat: { id: null, time: '0:00', isAvailable: false },
          sun: { id: null, time: '0:00', isAvailable: false },
          monthlyHours: '0:00',
          typeOfCat1: null,
          validFromValues: { cat1: null, cat2: null, cat3: null, role: null },
          validToValues: { cat1: null, cat2: null, cat3: null, role: null },
        },
        combinations
      );
    });
    return combinations;
  }

  /**
   * Traverses the tree starting from the given node and generates combinations based on the node's properties.
   *
   * @param node - The current node being traversed.
   * @param parentRoles - The set of parent roles. Set<[category Type, role name]>
   * @param combination - The current combination being generated.
   * @param combinations - The array to store the generated combinations.
   */
  traverseTree(
    node: CategoryLeaf,
    parentRoles: Set<[number, string, string, string]>,
    combination: Combination,
    combinations: Combination[]
  ) {
    if (!node) {
      return;
    }
    switch (node.type) {
      case 1:
        combination.cat1 = node.data.displayedName;
        combination.typeOfCat1 = node.data.type;
        combination.validFromValues.cat1 = node.data.validFrom;
        combination.validToValues.cat1 = node.data.validTo;
        /*Search for roles related to cat1 and add them to the parentRoles set*/
        if (node.roles) {
          const roles = Object.values(node.roles) as any[];
          if (roles.length !== 0) {
            roles.forEach((role) => {
              parentRoles.add([1, role.displayedName, role.validFrom, role.validTo]);
            });
          }
        }
        break;
      case 2:
        combination.cat2 = node.data.displayedName;
        combination.validFromValues.cat2 = node.data.validFrom;
        combination.validToValues.cat2 = node.data.validTo;
        if (node.roles) {
          /*Remove all roles that are not related to this cat2 node*/
          parentRoles = new Set([...parentRoles].filter((role) => role[0] !== 2));
          const roles = Object.values(node.roles) as any[];
          if (node.children) {
            const children = Object.values(node.children) as CategoryLeaf[];
            /*If the cat2 has no children (cat3), then add the possible combinations for this cat2.*/
            if (children.length === 0) {
              /* Add all combinations of this cat2 roles*/
              for (const role of roles) {
                combination.cat3 = '';
                combination.role = role.displayedName;
                combination.validFromValues.role = role.validFrom;
                combination.validToValues.role = role.validTo;
                this.getCombinationValidity(combination);
                combinations.push({ ...combination });
              }
              if (parentRoles.size !== 0) {
                /* Add all combinations of the parent roles*/
                parentRoles.forEach((role) => {
                  /* Check if the role belongs to cat1 */
                  if (role[0] === 1) {
                    combination.cat3 = '';
                    combination.role = role[1];
                    combination.validFromValues.role = role[2];
                    combination.validToValues.role = role[3];
                    this.getCombinationValidity(combination);
                    combinations.push({ ...combination });
                  }
                });
              }
            }
          }
          if (roles.length !== 0) {
            /* Add all combinations of this cat2 roles to the parent roles set*/
            roles.forEach((role) => {
              parentRoles.add([2, role.displayedName, role.validFrom, role.validTo]);
            });
          }
        }
        break;
      case 3:
        combination.cat3 = node.data.displayedName;
        combination.validFromValues.cat3 = node.data.validFrom;
        combination.validToValues.cat3 = node.data.validTo;
        if (node.roles) {
          /*Remove all roles that are not related to this cat3 node*/
          parentRoles = new Set([...parentRoles].filter((role) => role[0] !== 3));
          const roles = Object.values(node.roles) as any[];
          if (roles.length !== 0) {
            /* Add all combinations of this cat3 roles*/
            roles.forEach((role) => {
              combination.role = role.displayedName;
              combination.validFromValues.role = role.validFrom;
              combination.validToValues.role = role.validTo;
              this.getCombinationValidity(combination);
              combinations.push({ ...combination });
            });
          }
        }
        if (parentRoles.size !== 0) {
          /* Add all combinations of the parent roles*/
          parentRoles.forEach((role) => {
            if (role[0] !== 3) {
              combination.role = role[1];
              combination.validFromValues.role = role[2];
              combination.validToValues.role = role[3];
              this.getCombinationValidity(combination);
              combinations.push({ ...combination });
            }
          });
        }
        break;
    }

    if (node.children) {
      /*Traverse the children of the current node*/
      const children = Object.values(node.children) as CategoryLeaf[];
      children.forEach((child) => {
        this.traverseTree(child, parentRoles, combination, combinations);
      });
    }
  }

  /**
   * This method is used to calculate and set the validity range for a combination.
   * It calculates the maximum and minimum dates from the validFromValues and validToValues of the combination respectively.
   * If the maximum validFrom date is after the minimum validTo date or the minimum validTo date is before the maximum validFrom date,
   * it sets the validFrom and validTo of the combination to null.
   * Otherwise, it sets the validFrom and validTo of the combination to the maximum validFrom date and minimum validTo date respectively.
   *
   * @param combination - The combination object for which the
   * validity range is to be calculated and set.
   * The combination object should have validFromValues and validToValues properties, each of which should
   * be an object with cat1, cat2, cat3, and role properties.
   * Each property should be a string representing a date in 'YYYY-MM-DDTHH:mm:ss' format or null.
   * The method modifies the validFrom and validTo properties of the combination object.
   */
  private getCombinationValidity(combination: Combination) {
    const validFrom = moment.max(
      Object.values(combination.validFromValues).map((value) => moment(value || '1900-01-01T00:00:00'))
    );
    const validTo = moment.min(
      Object.values(combination.validToValues).map((value) => moment(value || '2999-12-31T00:00:00'))
    );

    if (validFrom.isAfter(validTo) || validTo.isBefore(validFrom)) {
      combination.validFrom = null;
      combination.validTo = null;
      return;
    }

    combination.validFrom = validFrom.format('YYYY-MM-DDTHH:mm:ss');
    combination.validTo = validTo.format('YYYY-MM-DDTHH:mm:ss');
    return;
  }

  filterCategoryListForAvailability(categoryArray: Array<any>, month: any) {
    let i = 0;
    while (i < categoryArray.length) {
      if (!this.isAvailable(categoryArray[i], month)) {
        categoryArray.splice(i, 1);
      } else {
        i++;
      }
    }
    return categoryArray;
  }

  /**
   * Checks if today's date falls between the ValidFrom and ValidTo dates of the ca2,
   * cat3 and role.
   *
   * @param element the element to be checked, it can be a cat2, cat3 or role.
   * @param month the month to be checked, need to be moment object.
   * @return if the today's date falls between the ValidFrom and ValidTo dates.
   * */
  private isAvailable(element: any, month: any): boolean {
    if (!element.validFrom && !element.validTo) {
      return true;
    }
    /* Code explanation: https://stackoverflow.com/a/40076732 */
    return (
      moment(element.validFrom).utc().startOf('day') <=  month.utc().clone().endOf('month') &&
      month.utc().clone().startOf('month') <= moment(element.validTo).utc().endOf('day')
    );
  }

  findCategoriesDataNameById(data: any[], id: number | null | undefined) {
    return data.find((c) => c.id === id)?.displayedName ?? '';
  }
}
