import { ErrorHandler, Injectable, Injector, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment, getBasePath } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import * as StackTraceParser from 'error-stack-parser';
import { of, Subscription } from 'rxjs';

@Injectable()
export class ErrorService implements ErrorHandler, OnDestroy {
  errorSubscription: Subscription;
  constructor(private readonly http: HttpClient, private readonly injector: Injector) {}

  ngOnDestroy() {
    /* to ensure that the subscription is not undefined */
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
  }

  handleError(error: Error): void {
    if (/Loading chunk [\d]+ failed/.test(error.message)) {
      // Angular error during development
      return;
    }

    if (error instanceof HttpErrorResponse && (error.status < 400 || error.status >= 500)) {
      // don't send backend errors back to backend, only send 4XX i.e. client errors
      return;
    }

    if (navigator.onLine && error) {
      const err = this.addContextInfo(error);
      this.errorSubscription = this.http
        .post(getBasePath() + '/v1/frontend-log', JSON.stringify(err))
        .pipe(
          catchError((e) => {
            console.log(e);
            return of('error while sending log');
          })
        )
        .subscribe();
    }
    console.error(error);
  }

  private addContextInfo(error) {
    const name = error.name || null;
    const time = new Date().getTime();
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const status = error.status || null;
    const message = error.message || error.toString();

    let stack;

    try {
      stack = StackTraceParser.parse(error);
    } catch (ignored) {
      stack = null;
    }

    return { name, time, url, status, message, stack };
  }
}
