import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { LoadingService } from '../../../services/loadingService/loading.service';
import { SidenavService } from '../../../services/sidenavService/sidenav.service';
import { ThemeService } from '../../../services/themeService/theme.service';
import { ActivatedRoute } from '@angular/router';
import { TimesheetTableViewService } from '../../../services/timesheetTableViewService/timesheet-table-view.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  isNewTimesheetTableView = true;
  private sidenavSubscriptions: Subscription[] = [];

  constructor(
    private readonly loadingService: LoadingService,
    private readonly sidenavService: SidenavService,
    private readonly themeService: ThemeService,
    private readonly route: ActivatedRoute,
    private readonly timesheetTableViewService: TimesheetTableViewService,
    public readonly authService: AuthService
  ) {
    this.sidenavSubscriptions.push(
      this.timesheetTableViewService.isNewTimesheetTableView$.subscribe((isNewTable) => {
        this.isNewTimesheetTableView = isNewTable;
      })
    );
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.sidenavSubscriptions.forEach((s) => s.unsubscribe());
  }

  login() {
    this.loadingService.startLoading();
    this.authService.login();
    this.sidenavService.close();
  }

  logout() {
    this.authService.logout();
    this.sidenavService.close();
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  isDefaultTheme() {
    return this.themeService.selectedTheme === 'default-theme';
  }

  isDefaultWidth() {
    return this.themeService.selectedWidth === 'default-width';
  }

  toggleWidth() {
    this.themeService.toggleWidth();
  }

  toggleTimesheetTableView() {
    this.timesheetTableViewService.toggleTimeSheetTableView();
  }

  isOnPage(pageName: string): boolean {
    return this.route.snapshot.url[0]?.path === pageName;
  }
}
