import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserGuard implements OnDestroy {
  private canActivateUserRoutes: boolean;
  private canActivateProtectedRoutes: boolean;
  private userGuardSubscription = [];

  constructor(private readonly authService: AuthService, private readonly router: Router) {
    this.userGuardSubscription.push(
      this.authService.hasUserRole$.subscribe((canActivate) => {
        this.canActivateUserRoutes = canActivate;
      })
    );
    this.userGuardSubscription.push(
      this.authService.canActivateProtectedRoutes$.subscribe((canActivate) => {
        this.canActivateProtectedRoutes = canActivate;
      })
    );
  }

  ngOnDestroy() {
    this.userGuardSubscription.forEach((subscription) => subscription.unsubscribe());
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.canActivateProtectedRoutes) {
      this.authService.login(next.routeConfig.path);
      return false;
    } else if (this.canActivateProtectedRoutes && !this.canActivateUserRoutes) {
      return false;
    }
    return true;
  }
}
