import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum TimesheetTableViewType {
  default = 'default-timesheet-table',
  new = 'new-timesheet-table',
}

@Injectable({
  providedIn: 'root',
})
export class TimesheetTableViewService {
  public selectedTimesheetTableView = TimesheetTableViewType.default;

  private selectedTimesheetTableSubject = new BehaviorSubject<string>(TimesheetTableViewType.default);
  private readonly isNewTimesheetTableViewSubject$ = new BehaviorSubject<boolean>(true);
  public isNewTimesheetTableView$ = this.isNewTimesheetTableViewSubject$.asObservable();

  constructor() {}

  public toggleTimeSheetTableView(): void {
    if (this.selectedTimesheetTableSubject.value === TimesheetTableViewType.default) {
      this.selectedTimesheetTableView = TimesheetTableViewType.new;
      this.isNewTimesheetTableViewSubject$.next(true);
    } else {
      this.selectedTimesheetTableView = TimesheetTableViewType.default;
      this.isNewTimesheetTableViewSubject$.next(false);
    }
    window.localStorage.setItem('selectedTimesheetTable', this.selectedTimesheetTableView);
    this.selectedTimesheetTableSubject.next(this.selectedTimesheetTableView);
  }

  public setSelectedTimeSheetTableView(selectedTimesheetTable: string): void {
    window.localStorage.setItem('selectedTimesheetTable', selectedTimesheetTable);
    this.isNewTimesheetTableViewSubject$.next(selectedTimesheetTable === 'new-timesheet-table');
    this.selectedTimesheetTableSubject.next(selectedTimesheetTable);
  }
}
