import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loadingService/loading.service';
import { License } from '../../shared/model/License';
import licenses from '../../../assets/licenses.json';
import { LicensesService } from '../../services/licensesService/licenses.service';
import { BackendLicenseReport } from '../../shared/model/BackendLicenseReport';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-license-information',
  templateUrl: './license-information.component.html',
  styleUrls: ['./license-information.component.scss'],
})
export class LicenseInformationComponent implements OnInit, OnDestroy {
  licenseMap: Map<string, License[]>; // maps license types to the license objects with the corresponding type
  private licenseInformationSubscription: Subscription;

  constructor(private readonly loadingService: LoadingService, private readonly licensesService: LicensesService) {}

  ngOnInit(): void {
    this.loadingService.stopLoading();
    this.licenseMap = new Map();
    this.addFrontendLicensesToLicenseMap(licenses);
    this.getBackendLicensesAndAddThemToLicenseMapAfterwards();
  }

  ngOnDestroy() {
    /* to ensure that the subscription is not undefined */
    if (this.licenseInformationSubscription) {
      this.licenseInformationSubscription.unsubscribe();
    }
  }

  addFrontendLicensesToLicenseMap(frontendLicenses: object): void {
    for (const [key, value] of Object.entries(frontendLicenses)) {
      const interpretedLicense = new License();
      let name = key;
      if (key.startsWith('@')) {
        name = key.substring(1);
      }
      interpretedLicense.name = name.split('@')[0];
      interpretedLicense.version = name.split('@')[1];
      interpretedLicense.licenses = value.licenses;
      interpretedLicense.url = value.repository;
      interpretedLicense.licenseUrl = value.licenseUrl;
      this.addToLicenseMap(interpretedLicense.licenses, interpretedLicense);
    }
  }

  addToLicenseMap(licenseType: string, license: License) {
    if (!this.licenseMap.has(licenseType)) {
      this.licenseMap.set(licenseType, []);
    }
    this.licenseMap.get(licenseType).push(license);
  }

  getBackendLicensesAndAddThemToLicenseMapAfterwards() {
    this.licenseInformationSubscription = this.licensesService
      .getLicenses()
      .subscribe((data: Object) => this.addBackendLicensesToLicenseMap(data));
  }

  addBackendLicensesToLicenseMap(licensesFromBackend): void {
    const licenseReportFromBackend = licensesFromBackend as BackendLicenseReport;
    for (const key of Object.keys(licenseReportFromBackend.dependencies)) {
      const interpretedLicense = new License();
      const backendLicense = licenseReportFromBackend.dependencies[key];
      interpretedLicense.name = backendLicense.moduleName;
      interpretedLicense.version = backendLicense.moduleVersion;
      interpretedLicense.licenses = backendLicense.moduleLicense;
      interpretedLicense.url = backendLicense.moduleUrl;
      interpretedLicense.licenseUrl = backendLicense.moduleLicenseUrl;
      this.addToLicenseMap(interpretedLicense.licenses, interpretedLicense);
    }
  }
}
